import React from "react"
import Layout from "../../components/member/Layout"
import calendar from "../../components/member/Calendar"

export default props => {
  var Calendar = calendar("reservation")
  const pageSlug = "reservation"
  const pageTitle = "施設利用｜箕澤屋クラウド"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Calendar></Calendar>
    </Layout>
  )
}
